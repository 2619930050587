var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { backgroundColor, borderRadius, borderTop, boxShadow, display, flex, flexDirection, height, maxWidth, minWidth, opacity, position, space, top, width, zIndex, } from 'styled-system';
import { SEARCH_SUGGESTIONS_Z_INDEX } from '../../constants/z-index';
import { borderTopColor, borderTopStyle, borderTopWidth, boxSizing, listStyle, overflowY, transitionProperty, transitionTimingFunction, } from '../../theme/system-utilities';
import useGetCenterProps from './hooks/center-props';
import getListStyles from './utils/get-list-styles';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  -webkit-appearance: none;\n  overscroll-behavior-y: contain;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  -webkit-appearance: none;\n  overscroll-behavior-y: contain;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, listStyle, overflowY, position, flex, flexDirection, top, borderTopStyle, borderTopWidth, borderTopColor, borderTop, borderRadius, boxShadow, width, minWidth, height, maxWidth, boxSizing, zIndex, space, transitionProperty, transitionTimingFunction, opacity, backgroundColor);
Container.defaultProps = {
    listStyle: 'none',
    overflowY: 'auto',
    flex: '1',
    position: [null, 'absolute'],
    width: 1,
    height: ['calc(100% - 68px)', 'auto'], // setting correct height of scrollable container requires to subtract the height of search box
    m: 'sp_0',
    boxSizing: 'border-box',
    zIndex: [null, SEARCH_SUGGESTIONS_Z_INDEX],
    backgroundColor: 'sys.neutral.background.default',
    transitionProperty: 'opacity, box-shadow',
    boxShadow: '0 0 0 0 rgba(44, 54, 56, 0.3)',
    borderRadius: [null, 'large'],
    flexDirection: ['column', null, 'row'],
    px: 'sp_8',
    pt: 'sp_8',
    top: 'calc(100% + 12px)',
};
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, width);
InnerContainer.defaultProps = {
    display: ['block', null, 'flex'],
    width: '100%',
};
var SuggestionsListContainer = function (_a) {
    var hasExtraPadding = _a.hasExtraPadding, timeout = _a.timeout, isBodyOverlayVisible = _a.isBodyOverlayVisible, children = _a.children, open = _a.open, containerRef = _a.containerRef, isSingleColumn = _a.isSingleColumn, inputContainerRef = _a.inputContainerRef, props = __rest(_a, ["hasExtraPadding", "timeout", "isBodyOverlayVisible", "children", "open", "containerRef", "isSingleColumn", "inputContainerRef"]);
    var listRef = useRef(null);
    var centerAutoSuggestProps = useGetCenterProps({
        listRef: listRef,
        inputContainerRef: inputContainerRef,
        open: open,
        isSingleColumn: isSingleColumn,
    });
    var styles = getListStyles({
        hasExtraPadding: hasExtraPadding,
        timeout: timeout,
        isBodyOverlayVisible: isBodyOverlayVisible,
        open: open,
        isSingleColumn: isSingleColumn,
    });
    return (React.createElement(Container, __assign({}, props, styles, centerAutoSuggestProps, { "data-testid": "suggestions-list", "data-cnstrc-autosuggest": "", ref: containerRef }),
        React.createElement(InnerContainer, { "data-testid": "suggestions-list-inner-container", ref: listRef }, children)));
};
export default SuggestionsListContainer;
var templateObject_1, templateObject_2;
