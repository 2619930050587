var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useTransition } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { useBodyOverlay } from '../../../hooks/body-overlay';
import { useKeyboardClose } from '../../../hooks/keyboard';
import { useOnClickOutside } from '../../../hooks/mouse';
import { useInput } from './input';
import { getBackButtonClickHandler } from './input/handlers';
import { useRecentSearches } from './recent-searches';
import { useSuggestions } from './suggestions';
var useBodyOverlayEffect = function (_a) {
    var matchesTablet = _a.matchesTablet, focused = _a.focused, isSuggestionsOpened = _a.isSuggestionsOpened, setIsBodyOverlayVisible = _a.setIsBodyOverlayVisible;
    useEffect(function () {
        setIsBodyOverlayVisible(matchesTablet && (focused || isSuggestionsOpened));
    }, [focused, isSuggestionsOpened]);
};
var getSearchSuggestionOptions = function (_a) {
    var suggestions = _a.suggestions, isOpen = _a.isOpen, suggestionsHandlers = _a.suggestionsHandlers, recentSearches = _a.recentSearches, handleSelect = _a.handleSelect;
    return {
        suggestions: suggestions.suggestions,
        productSuggestions: suggestions.productSuggestions,
        listItems: suggestions.listItems,
        flags: { isOpen: isOpen },
        handlers: __assign(__assign({}, suggestionsHandlers), ((recentSearches === null || recentSearches === void 0 ? void 0 : recentSearches.flags.isOpen)
            ? { handleSelect: recentSearches.handlers.handleSelect }
            : { handleSelect: handleSelect })),
    };
};
export var useSearch = function (_a) {
    var matchesTablet = _a.matchesTablet, config = _a.config, params = _a.params, withRecentSearches = _a.withRecentSearches, withProductSuggestions = _a.withProductSuggestions, shopName = _a.shopName, filterQuery = _a.filterQuery, overlayStacking = _a.overlayStacking, withInputFilled = _a.withInputFilled;
    var isMobile = !matchesTablet;
    var isKeyboardOpen = useDetectKeyboardOpen();
    var _b = useBodyOverlay(overlayStacking), isBodyOverlayVisible = _b.isBodyOverlayVisible, setIsBodyOverlayVisible = _b.setIsBodyOverlayVisible;
    var input = useInput({ isMobile: isMobile, shopName: shopName, withInputFilled: withInputFilled });
    var _c = __read(useState(false), 2), isContainerHidden = _c[0], setIsContainerHidden = _c[1];
    var _d = input.handlers, clearValue = _d.clearValue, unlockSearchAndClearInput = _d.unlockSearchAndClearInput, inputHandlers = __rest(_d, ["clearValue", "unlockSearchAndClearInput"]);
    var isInputFocused = input.flags.focused;
    var suggestions = useSuggestions({
        config: config,
        params: params,
        inputValue: input.value,
        setIsBodyOverlayVisible: setIsBodyOverlayVisible,
        isInputFocused: isInputFocused,
        isEditedByUser: input.flags.isEditedByUser,
        isMobile: isMobile,
        unlockSearchAndClearInput: input.handlers.unlockSearchAndClearInput,
        isContainerHidden: isContainerHidden,
        setIsContainerHidden: setIsContainerHidden,
        withProductSuggestions: withProductSuggestions,
        urlParams: filterQuery,
    });
    var _e = suggestions.handlers, clear = _e.clear, handleSelect = _e.handleSelect, suggestionsHandlers = __rest(_e, ["clear", "handleSelect"]);
    var recentSearches = useRecentSearches({
        isEnabled: withRecentSearches,
        isSuggestionsOpen: suggestions.flags.isOpen,
        isInputFocused: isInputFocused,
        isContainerHidden: isContainerHidden,
        isInputLocked: input.flags.isLocked,
        isMobile: isMobile,
        inputValue: input.value,
        inputRef: input.refs.input,
    });
    var isOpen = suggestions.flags.isOpen || !!(recentSearches === null || recentSearches === void 0 ? void 0 : recentSearches.flags.isOpen);
    var handleBackButtonClick = getBackButtonClickHandler({
        unlockSearchAndClearInput: unlockSearchAndClearInput,
        clearSuggestions: clear,
    });
    useBodyOverlayEffect({
        matchesTablet: matchesTablet,
        focused: isInputFocused,
        isSuggestionsOpened: isOpen,
        setIsBodyOverlayVisible: setIsBodyOverlayVisible,
    });
    useEffect(function () {
        if (isInputFocused) {
            setIsContainerHidden(false);
        }
    }, [isInputFocused]);
    var _f = __read(useTransition(), 2), startTransition = _f[1];
    var handleClickOutSideClick = function () {
        if (isOpen) {
            startTransition(function () {
                setIsBodyOverlayVisible(false);
                setIsContainerHidden(true);
                input.handlers.handleBlur();
                if (input.flags.isLocked) {
                    input.handlers.unlockSearch();
                }
            });
        }
    };
    useKeyboardClose(isOpen, handleClickOutSideClick);
    useOnClickOutside(handleClickOutSideClick, input.refs.container);
    return {
        input: {
            value: input.value,
            actionPath: input.actionPath,
            refs: input.refs,
            placeholder: input.placeholder,
            flags: {
                focused: isInputFocused,
                isLocked: input.flags.isLocked,
                isClearButtonHidden: input.flags.isClearButtonHidden,
                isKeyboardOpen: isKeyboardOpen,
                isBodyOverlayVisible: isBodyOverlayVisible,
            },
            handlers: __assign(__assign({}, inputHandlers), { handleBackButtonClick: handleBackButtonClick }),
        },
        suggestions: getSearchSuggestionOptions({
            suggestions: suggestions,
            isOpen: isOpen,
            suggestionsHandlers: suggestionsHandlers,
            recentSearches: recentSearches,
            handleSelect: handleSelect,
        }),
        recentSearches: recentSearches,
    };
};
