var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useMemo, useState } from 'react';
import { ArticleImageFormat } from 'graphql-ts-types';
import { executeQuery } from '../../../../gql';
import { useGQLLocale } from '../../../../hooks/locale';
import isWebpSupported from '../../../../utils/isWebpSupported';
import query from '../../gql/query/suggestions.gql';
import { attachEncodedUrl, getInputLength } from '../../utils/search';
import { useSuggestionsHandlers } from './handlers';
var fetchSuggestions = function (variables) {
    return executeQuery({
        query: query,
        variables: __assign(__assign({}, variables), (isWebpSupported() && { format: ArticleImageFormat.Webp })),
    });
};
var isProductSuggestion = function (suggestion) { return 'productImage' in suggestion; };
var isKeywordSuggestion = function (suggestion) { return !isProductSuggestion(suggestion); };
var getSuggestions = function (_a) {
    var suggestionsList = _a.suggestionsList, suggestionsLimit = _a.suggestionsLimit, productsLimit = _a.productsLimit, urlParams = _a.urlParams;
    var nonEmptySuggestions = suggestionsList.filter(function (item) { return item && Object.keys(item).length; });
    var suggestions = nonEmptySuggestions
        .filter(isKeywordSuggestion)
        .map(function (suggestion) { return attachEncodedUrl(suggestion, urlParams); })
        .slice(0, suggestionsLimit);
    var productSuggestions = nonEmptySuggestions
        .filter(isProductSuggestion)
        .slice(0, productsLimit);
    return { suggestions: suggestions, productSuggestions: productSuggestions };
};
var shouldOpenSuggestions = function (_a) {
    var inputValue = _a.inputValue, searchThreshold = _a.searchThreshold, suggestions = _a.suggestions, isContainerHidden = _a.isContainerHidden;
    return function () {
        return getInputLength(inputValue) >= searchThreshold && suggestions.length > 0 && !isContainerHidden;
    };
};
var getNames = function (items) { return items.map(function (_a) {
    var name = _a.name;
    return name;
}); };
export var useSuggestions = function (_a) {
    var inputValue = _a.inputValue, setIsBodyOverlayVisible = _a.setIsBodyOverlayVisible, isEditedByUser = _a.isEditedByUser, isInputFocused = _a.isInputFocused, isMobile = _a.isMobile, _b = _a.config, searchThreshold = _b.searchThreshold, suggestionsLimit = _b.suggestionsLimit, productsLimit = _b.productsLimit, searchDebounce = _b.searchDebounce, unlockSearchAndClearInput = _a.unlockSearchAndClearInput, params = _a.params, withProductSuggestions = _a.withProductSuggestions, urlParams = _a.urlParams, isContainerHidden = _a.isContainerHidden, setIsContainerHidden = _a.setIsContainerHidden;
    var locale = useGQLLocale();
    var _c = __read(useState([]), 2), suggestions = _c[0], setSuggestions = _c[1];
    var _d = __read(useState([]), 2), productSuggestions = _d[0], setProductSuggestions = _d[1];
    var clear = function () {
        setSuggestions([]);
        setProductSuggestions([]);
    };
    var isOpen = useMemo(shouldOpenSuggestions({
        inputValue: inputValue,
        suggestions: suggestions,
        searchThreshold: searchThreshold,
        isContainerHidden: isContainerHidden,
    }), [inputValue, suggestions, searchThreshold, isContainerHidden]);
    var listItems = useMemo(function () { return getNames(suggestions); }, [suggestions]);
    var handlers = useSuggestionsHandlers({
        suggestions: suggestions,
        listItems: listItems,
        inputValue: inputValue,
        isMobile: isMobile,
        clear: clear,
        unlockSearchAndClearInput: unlockSearchAndClearInput,
        setIsBodyOverlayVisible: setIsBodyOverlayVisible,
    });
    useEffect(function () {
        var timeout = null;
        var canceled = false;
        var prefix = inputValue.trim();
        if (prefix.length >= searchThreshold) {
            timeout = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var response, result, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, fetchSuggestions(__assign({ prefix: prefix, locale: locale, urlParams: urlParams, withProductSuggestions: withProductSuggestions }, params))];
                        case 1:
                            response = _b.sent();
                            if (!canceled) {
                                result = getSuggestions({
                                    suggestionsList: response.suggestions,
                                    suggestionsLimit: suggestionsLimit,
                                    productsLimit: productsLimit,
                                    urlParams: urlParams,
                                });
                                if (!isEditedByUser && !isInputFocused) {
                                    setIsContainerHidden(true);
                                }
                                setSuggestions(result.suggestions);
                                setProductSuggestions(result.productSuggestions);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            _a = _b.sent();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, isEditedByUser ? searchDebounce : 0);
        }
        return function () {
            clearTimeout(timeout);
            canceled = true;
        };
    }, [
        inputValue,
        isEditedByUser,
        isInputFocused,
        searchThreshold,
        suggestionsLimit,
        productsLimit,
        searchDebounce,
    ]);
    return {
        suggestions: suggestions,
        productSuggestions: productSuggestions,
        listItems: listItems,
        flags: { isOpen: isOpen },
        handlers: handlers,
    };
};
