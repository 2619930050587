var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EventBusEvent } from '../enums/EventBus';
import eventBus from '../event-bus/EventBus';
export var is2DArray = function (array) {
    return Array.isArray(array) && Array.isArray(array[0]);
};
export var transformTopNavItems = function (items) {
    if (is2DArray(items)) {
        return items;
    }
    var aggregation = items.reduce(function (acc, item) {
        if (item.promoImg || item.html) {
            return __assign(__assign({}, acc), { promo: __spreadArray(__spreadArray([], __read(acc.promo), false), [item], false) });
        }
        return __assign(__assign({}, acc), { menu: __spreadArray(__spreadArray([], __read(acc.menu), false), [
                __assign(__assign({}, item), { children: [__spreadArray([], __read(item.children), false)] }),
            ], false) });
    }, { menu: [], promo: [] });
    return aggregation.promo.length > 0
        ? [__spreadArray([], __read(aggregation.menu), false), __spreadArray([], __read(aggregation.promo), false)]
        : [__spreadArray([], __read(aggregation.menu), false)];
};
export var SideNavType;
(function (SideNavType) {
    SideNavType["Global"] = "global";
    SideNavType["ShopInShop"] = "shop-in-shop";
})(SideNavType || (SideNavType = {}));
export var openSideNav = function (_a) {
    var _b = _a === void 0 ? {} : _a, selectedItem = _b.selectedItem, _c = _b.type, type = _c === void 0 ? SideNavType.Global : _c;
    return eventBus.emit(EventBusEvent.ToggleSideNav, {
        isOpen: true,
        selectedItem: selectedItem,
        type: type,
    });
};
