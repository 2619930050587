var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dispatchTrackingEventGA4 } from '../../../../common/tracking/dispatchTrackingEventGA4';
import { Element, NavigationParent } from '../../../../common/types/tracking';
var TopNavigationEvent;
(function (TopNavigationEvent) {
    TopNavigationEvent["GlobalHeaderClick"] = "global-header-top-nav_click";
    TopNavigationEvent["ShopInShopNavClick"] = "shop-in-shop-top-nav_click";
})(TopNavigationEvent || (TopNavigationEvent = {}));
export var trackNavigationItemClick = function (_a) {
    var _b = _a.parentComponent, parentComponent = _b === void 0 ? NavigationParent.GlobalHeader : _b, _c = _a.element, element = _c === void 0 ? Element.Link : _c, label = _a.label, length = _a.length, position = _a.position, shopName = _a.shopName;
    dispatchTrackingEventGA4(__assign({ event: parentComponent === NavigationParent.ShopInShopNav
            ? TopNavigationEvent.ShopInShopNavClick
            : TopNavigationEvent.GlobalHeaderClick, element: element, clicked_text: label, list_length: length, list_position: position, nav_level: 'L1' }, (shopName && { shop_name: shopName })));
};
export var trackCategoriesButtonClick = function (label, shopName) {
    dispatchTrackingEventGA4({
        event: TopNavigationEvent.ShopInShopNavClick,
        element: Element.Button,
        clicked_text: label,
        shop_name: shopName,
    });
};
