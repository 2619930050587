var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import { OVERLAY_Z_INDEX } from '../constants/z-index';
var createOverlay = function (zIndex) {
    var overlayElement = document.createElement('div');
    overlayElement.setAttribute('data-testid', 'body-overlay');
    overlayElement.style.position = 'fixed';
    overlayElement.style.top = '0';
    overlayElement.style.left = '0';
    overlayElement.style.right = '0';
    overlayElement.style.bottom = '0';
    overlayElement.style.zIndex = zIndex.toString();
    overlayElement.style.background = 'rgba(0, 0, 0, 0.6)';
    return overlayElement;
};
export var useBodyOverlay = function (overlayZIndex) {
    if (overlayZIndex === void 0) { overlayZIndex = OVERLAY_Z_INDEX; }
    var _a = __read(useState(false), 2), isBodyOverlayVisible = _a[0], setIsBodyOverlayVisible = _a[1];
    useEffect(function () {
        var overlayElement;
        if (isBodyOverlayVisible) {
            overlayElement = createOverlay(overlayZIndex);
            document.body.prepend(overlayElement);
        }
        return function () {
            if (overlayElement) {
                document.body.removeChild(overlayElement);
            }
        };
    }, [isBodyOverlayVisible, overlayZIndex]);
    return { isBodyOverlayVisible: isBodyOverlayVisible, setIsBodyOverlayVisible: setIsBodyOverlayVisible };
};
